import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ContentCenter from '../components/sections/contentCenter'

const Privacy = () => (
	<Layout>
		<Seo
			title='Политика конфиденциальности и правила'
			meta={[{ name: `robots`, content: `noindex, nofollow`,}]}
		/>
		<ContentCenter
			subtitle='Правила'
			title='Условия использования и политика конфиденциальности'
			intro='Мы сами не собираем о тебе никаких данных, но как и везде на нашем сайте установлены скрипты известных интернет гигантов, которые собирают аналитику. В браузерах сейчас есть возможности все это отключить и удалить куки. Продолжая пользоваться сайтом, ты принимаешь все условия использования и нашу политику конфиденциальности.'
		>
			<p>
				Никто это не читает, но для соблюдения дурацких законов мы здесь пропишем все что надо немного позже. Так что если тебе интересно, заходи. Мы разместим здесь текст, который можно будет использовать как образец для своего сайта.
			</p>
		</ContentCenter>
	</Layout>
)

export default Privacy